
import { defineComponent, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import campaignApi from "@/core/services/Campaign";
import { Field } from "vee-validate";
import { useI18n } from "vue-i18n";
import buttonHandle from "@/shared.js";

export default defineComponent({
    name: "administration-campaign",
    components: { Field },
    data: function () {
        return {
            campaign: {
                id: null,
                label: "",
                code: "",
            },
            campaigns: [],
        };
    },
    methods: {
        initAdd() {
            this.campaign = {
                id: null,
                label: "",
                code: "",
            };
        },
        initEdit(campaign) {
            this.campaign = {
                id: campaign.id,
                label: campaign.label,
                code: campaign.code
            };
        },
        addCampaign() {
            buttonHandle.handleWaitingButton(this.submitButton);
            if (this.campaign.id) {
                campaignApi.updateCampaign(this.campaign.id, this.campaign).then(() => {
                    buttonHandle.handleValidatedButton(this.submitButton);
                    this.getCampaigns();
                });
            } else {
                campaignApi.addCampaign(this.campaign).then(() => {
                    buttonHandle.handleValidatedButton(this.submitButton);
                    this.getCampaigns();
                });
            }
        },
        getCampaigns() {
            campaignApi.getCampaigns().then((response) => {
                this.campaigns = response.data["campaigns"];
            });
        },
    },
    setup() {
        const { t } = useI18n();
        setCurrentPageBreadcrumbs("Campagnes", []);
        const submitButton = ref<HTMLElement | null>(null);

        return { t, submitButton };
    },
    watch: {
        "$i18n.locale": function () {
            setCurrentPageBreadcrumbs("Campagnes", []);
        },
    },
    created() {
        this.getCampaigns();
    },
});
